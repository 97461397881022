import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const CloudWord = lazy(() => import('sections/hero/CloudWord.js'));
const ServicesCloudW = lazy(() => import('sections/services/ServicesCloudW.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const CloudWordP = lazy(() => import('sections/services/CloudWord.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {



    return (
      <div>
        <Helmet>
          <title>Cloud WordPress Hosting en Colombia: Potencia y escalabilidad</title>
          <meta name="description" content="Experimenta la potencia y escalabilidad del Hosting Cloud WordPress en Colombia. Optimiza el rendimiento de tu sitio web con balanceador de carga intuitivo." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <CloudWord />
            <CloudWordP />
            <ServicesCloudW />
            <Llamenos />
            <BotonCloud />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`